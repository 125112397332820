<template>
  <b-card
    v-if="loading"
    class="mb-1 mt-1 issues-loader"
  >
    <b-col class="d-flex justify-content-center">
      <b-spinner label="Loading..." />
    </b-col>
  </b-card>
  <b-card
    v-else
  >
    <b-col>
      <b-row>
        <b-col>
          <h4>
            Ride Incidents
          </h4>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <new-table
            :routes="routes"
            :export-file-name="exportFileName"
            :columns="columns"
            :items="incidents"
            table-name="rides-incidents-list"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-card>
</template>
<script>
import {
  VBTooltip, BCard, BCol,
} from 'bootstrap-vue'
import Moment from 'moment'
import NewTable from '@/views/new-table.vue'

export default {
  components: {
    BCard,
    BCol,
    NewTable,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      moment: Moment,
      exportFileName: `rides-incidents-list-${Moment(new Date()).format('YYYY-MM-DD')}`,
      routes: {
        ride: 'view-ride',
      },
      filter: '',
      incidents: [],
      columns: [
        {
          label: 'Ride',
          data: 'ride_id',
          custom_template: {
            template_name: 'rides.incidents.model',
          },
        },
        {
          label: 'OS',
          data: 'app_os',
          firstnode: 'app_os',
        },
        {
          label: 'Reports',
          data: 'reports',
          firstnode: 'report',
        },
        {
          label: 'Status',
          data: 'status',
          custom_template: {
            template_name: 'rides.incidents.status',
          },
        },
        {
          label: 'Date',
          data: 'created_at',
          custom_template: {
            template_name: 'rides.incidents.created_at',
          },
          calculateCellValue(data) {
            return Moment.utc(data.created_at * 1000).local().format('ll hh:mma')
          },

        },
      ],
    }
  },
  mounted() {
    this.loading = true
    this.$http.get(this.$resources.rides.incidents).then(response => {
      const { data: { data: { rows } } } = response
      this.loading = false
      this.incidents = rows
    })
  },
}
</script>

<style lang="scss" scoped>
  .issues-loader {
     .card-body {
       min-height: 900px;
       display: flex;
       align-items: center;
       justify-content: center;
    }
  }
</style>
